<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="fa fa-picture-o"></i>CoreUI Icons <b-badge variant="info">New</b-badge>
        <div class="card-header-actions">
          <a href="https://github.com/coreui/coreui-icons" rel="noreferrer noopener" target="_blank" class="card-header-action">
            <small class="text-muted">Github</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row class="text-center">
          <b-col xs="6" sm="4" md="3">
            <i class="cui-account-logout icons font-2xl d-block mt-4"></i>
            <div>account-logout</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-action-redo icons font-2xl d-block mt-4"></i>
            <div>action-redo</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-action-undo icons font-2xl d-block mt-4"></i>
            <div>action-undo</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-align-center icons font-2xl d-block mt-4"></i>
            <div>align-center</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-align-left icons font-2xl d-block mt-4"></i>
            <div>align-left</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-align-right icons font-2xl d-block mt-4"></i>
            <div>align-right</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-aperture icons font-2xl d-block mt-4"></i>
            <div>aperture</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-bottom icons font-2xl d-block mt-4"></i>
            <div>arrow-bottom</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-circle-bottom icons font-2xl d-block mt-4"></i>
            <div>arrow-circle-bottom</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-circle-left icons font-2xl d-block mt-4"></i>
            <div>arrow-circle-left</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-circle-right icons font-2xl d-block mt-4"></i>
            <div>arrow-circle-right</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-circle-top icons font-2xl d-block mt-4"></i>
            <div>arrow-circle-top</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-left icons font-2xl d-block mt-4"></i>
            <div>arrow-left</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-right icons font-2xl d-block mt-4"></i>
            <div>arrow-right</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-thick-bottom icons font-2xl d-block mt-4"></i>
            <div>arrow-thick-bottom</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-thick-left icons font-2xl d-block mt-4"></i>
            <div>arrow-thick-left</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-thick-right icons font-2xl d-block mt-4"></i>
            <div>arrow-thick-right</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-thick-top icons font-2xl d-block mt-4"></i>
            <div>arrow-thick-top</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-arrow-top icons font-2xl d-block mt-4"></i>
            <div>arrow-top</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-audio icons font-2xl d-block mt-4"></i>
            <div>audio</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-audio-spectrum icons font-2xl d-block mt-4"></i>
            <div>audio-spectrum</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-badge icons font-2xl d-block mt-4"></i>
            <div>badge</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-ban icons font-2xl d-block mt-4"></i>
            <div>ban</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-bar-chart icons font-2xl d-block mt-4"></i>
            <div>bar-chart</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-battery-empty icons font-2xl d-block mt-4"></i>
            <div>battery-empty</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-battery-full icons font-2xl d-block mt-4"></i>
            <div>battery-full</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-beaker icons font-2xl d-block mt-4"></i>
            <div>beaker</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-bluetooth icons font-2xl d-block mt-4"></i>
            <div>bluetooth</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-bold icons font-2xl d-block mt-4"></i>
            <div>bold</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-bolt icons font-2xl d-block mt-4"></i>
            <div>bolt</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-book icons font-2xl d-block mt-4"></i>
            <div>book</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-bookmark icons font-2xl d-block mt-4"></i>
            <div>bookmark</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-box icons font-2xl d-block mt-4"></i>
            <div>box</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-briefcase icons font-2xl d-block mt-4"></i>
            <div>briefcase</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-british-pound icons font-2xl d-block mt-4"></i>
            <div>british-pound</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-browser icons font-2xl d-block mt-4"></i>
            <div>browser</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-brush icons font-2xl d-block mt-4"></i>
            <div>brush</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-bug icons font-2xl d-block mt-4"></i>
            <div>bug</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-bullhorn icons font-2xl d-block mt-4"></i>
            <div>bullhorn</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-caret-bottom icons font-2xl d-block mt-4"></i>
            <div>caret-bottom</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-caret-left icons font-2xl d-block mt-4"></i>
            <div>caret-left</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-caret-right icons font-2xl d-block mt-4"></i>
            <div>caret-right</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-caret-top icons font-2xl d-block mt-4"></i>
            <div>caret-top</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-cart icons font-2xl d-block mt-4"></i>
            <div>cart</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-chat icons font-2xl d-block mt-4"></i>
            <div>chat</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-check icons font-2xl d-block mt-4"></i>
            <div>check</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-chevron-bottom icons font-2xl d-block mt-4"></i>
            <div>chevron-bottom</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-chevron-left icons font-2xl d-block mt-4"></i>
            <div>chevron-left</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-chevron-right icons font-2xl d-block mt-4"></i>
            <div>chevron-right</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-chevron-top icons font-2xl d-block mt-4"></i>
            <div>chevron-top</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-circle-check icons font-2xl d-block mt-4"></i>
            <div>circle-check</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-circle-x icons font-2xl d-block mt-4"></i>
            <div>circle-x</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-clipboard icons font-2xl d-block mt-4"></i>
            <div>clipboard</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-clock icons font-2xl d-block mt-4"></i>
            <div>clock</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-cloud icons font-2xl d-block mt-4"></i>
            <div>cloud</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-cloud-upload icons font-2xl d-block mt-4"></i>
            <div>cloud-upload</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-cloudy icons font-2xl d-block mt-4"></i>
            <div>cloudy</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-social-linkedin icons font-2xl d-block mt-4"></i>
            <div>social-linkedin</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-social-spotify icons font-2xl d-block mt-4"></i>
            <div>social-spotify</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-basket-loaded icons font-2xl d-block mt-4"></i>
            <div>basket-loaded</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-bell icons font-2xl d-block mt-4"></i>
            <div>bell</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-calculator icons font-2xl d-block mt-4"></i>
            <div>calculator</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-calendar icons font-2xl d-block mt-4"></i>
            <div>calendar</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-camera icons font-2xl d-block mt-4"></i>
            <div>camera</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-chart icons font-2xl d-block mt-4"></i>
            <div>chart</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-cloud-download icons font-2xl d-block mt-4"></i>
            <div>cloud-download</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-cursor icons font-2xl d-block mt-4"></i>
            <div>cursor</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-cursor-move icons font-2xl d-block mt-4"></i>
            <div>cursor-move</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-drop icons font-2xl d-block mt-4"></i>
            <div>drop</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-energy icons font-2xl d-block mt-4"></i>
            <div>energy</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-envelope-letter icons font-2xl d-block mt-4"></i>
            <div>envelope-letter</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-equalizer icons font-2xl d-block mt-4"></i>
            <div>equalizer</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-globe icons font-2xl d-block mt-4"></i>
            <div>globe</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-graph icons font-2xl d-block mt-4"></i>
            <div>graph</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-home icons font-2xl d-block mt-4"></i>
            <div>home</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-layers icons font-2xl d-block mt-4"></i>
            <div>layers</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-list icons font-2xl d-block mt-4"></i>
            <div>list</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-location-pin icons font-2xl d-block mt-4"></i>
            <div>location-pin</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-map icons font-2xl d-block mt-4"></i>
            <div>map</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-note icons font-2xl d-block mt-4"></i>
            <div>note</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-options icons font-2xl d-block mt-4"></i>
            <div>options</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-pencil icons font-2xl d-block mt-4"></i>
            <div>pencil</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-people icons font-2xl d-block mt-4"></i>
            <div>people</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-pie-chart icons font-2xl d-block mt-4"></i>
            <div>pie-chart</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-puzzle icons font-2xl d-block mt-4"></i>
            <div>puzzle</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-screen-desktop icons font-2xl d-block mt-4"></i>
            <div>screen-desktop</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-screen-smartphone icons font-2xl d-block mt-4"></i>
            <div>screen-smartphone</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-settings icons font-2xl d-block mt-4"></i>
            <div>settings</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-social-facebook icons font-2xl d-block mt-4"></i>
            <div>social-facebook</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-social-skype icons font-2xl d-block mt-4"></i>
            <div>social-skype</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-social-twitter icons font-2xl d-block mt-4"></i>
            <div>social-twitter</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-speech icons font-2xl d-block mt-4"></i>
            <div>speech</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-speedometer icons font-2xl d-block mt-4"></i>
            <div>speedometer</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-star icons font-2xl d-block mt-4"></i>
            <div>star</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-user icons font-2xl d-block mt-4"></i>
            <div>user</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-user-female icons font-2xl d-block mt-4"></i>
            <div>user-female</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-user-follow icons font-2xl d-block mt-4"></i>
            <div>user-follow</div>
          </b-col>
          <b-col xs="6" sm="4" md="3">
            <i class="cui-user-unfollow icons font-2xl d-block mt-4"></i>
            <div>user-unfollow</div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>

</template>

<script>
export default {
  name: 'CoreUIIcons'
}
</script>
